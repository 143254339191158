<template>
    <div class="virtual-account" @scroll="onScroll">
        <StackRouterHeaderBar :showTitle="showTitle" title="결제 안내" />
        <div class="page-header">
            <div class="title" v-html="title"></div>
        </div>
        <div class="body">
            <div class="row">
                <div class="title">입금은행</div>
                <div class="content">우리은행</div>
            </div>
            <div class="row account-row">
                <div class="title">계좌번호</div>
                <div class="account">
                    <div class="content">{{ userVirtualAccount.virtual_account.account_no }}</div>
                    <div class="copy-btn" v-html="$translate('COPY')" @click="copyAccount" />
                </div>
            </div>
            <div class="row">
                <div class="title">예금주</div>
                <div class="content">바닐라브릿지</div>
            </div>
            <div class="row">
                <div class="title">입금금액</div>
                <div class="content money">{{ userVirtualAccount.amount | currency }}원 <span>(부가세 포함)</span></div>
            </div>
            <div class="row">
                <div class="title">입금기한</div>
                <div class="content">{{ userVirtualAccount.valid_until | formatDate('YYYY.MM.DD | HH:mm') }} 까지</div>
            </div>
        </div>
        <div class="bottom">
            <div class="button" v-html="'확인'" @click="close()" />
        </div>
    </div>
</template>

<script>
import { currency } from '@/filters'

export default {
    name: 'VirtualAccountPage',
    components: {},
    props: {
        userVirtualAccount: {
            type: Object,
        },
    },
    data: () => ({
        showTitle: false,
    }),
    computed: {
        title() {
            return `아래 계좌에<br>${currency(this.userVirtualAccount.amount)}원을 입금해 주세요`
        },
    },
    methods: {
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 200
        },
        onClickBackButton() {
            this.$router.go(-1)
        },
        async close() {
            await Promise.all([this.$store.dispatch('loadBadges'), this.$store.dispatch('loadMe')])
            this.$stackRouter.pop()
        },
        copyAccount() {
            setTimeout(() => {
                this.$copyText(this.userVirtualAccount.virtual_account.account_no)
                    // $copyText 라이브러리가 Uncaught in promise 하는 경우가 존재하여 resolve인 경우만 핸들링
                    .then(() => {
                        this.$toast.success('계좌번호가 복사되었습니다.')
                    })
                    .catch(e => console.log(e))
            }, 700)
        },
    },
}
</script>

<style lang="scss" scoped>
.virtual-account {
    overflow-y: auto;
    position: relative;
    padding-bottom: 110px;

    hr {
        border-bottom: solid 1px $grey-02;
    }

    .page-header {
        padding: 0 16px;
        margin: 0;
        // margin: 12px 16px 32px;

        .title {
            color: black;
            font-size: 24px;
            line-height: 36px;
            // font-weight: normal;
            // font-family: NotoSans-Regular;
            @include f-medium;
        }
    }

    .desc {
        margin-top: 40px;
        padding: 0 16px;
        font-size: 15px;
        color: $grey-08;
    }

    .body {
        border-radius: 20px;
        border: solid 1px $grey-03;
        margin: 40px 16px 0 16px;
        padding: 28px 24px;
        font-size: 15px;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &.account-row {
                margin-bottom: 16px;
            }

            .title {
                color: $grey-06;
                width: 33%;
            }

            .account {
                @include center;

                .copy-btn {
                    margin-left: 4px;
                    width: fit-content;
                    padding: 3px 8px;
                    border: 1px solid $grey-03;
                    font-size: 13px;
                    border-radius: 6px;
                    color: $grey-09;
                }
            }

            .content {
                color: black;

                &.money {
                    color: $pink-deep;
                    @include f-bold;

                    span {
                        @include f-regular;
                    }
                }
            }
        }
    }

    .bottom-desc {
        position: absolute;
        bottom: 88px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            font-size: 13px;
            color: $purple-primary;
        }
    }

    .bottom {
        position: absolute;
        bottom: 0;
        padding: 16px;
        width: 100%;
        border-top: solid 1px $grey-02;

        div {
            width: 100%;
            height: 48px;
            border-radius: 8px;
            background-color: $purple-primary;
            @include f-medium;
            font-size: 15px;
            color: white;
            line-height: 48px;
            text-align: center;
        }
    }
}
</style>
